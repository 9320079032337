import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    const { config } = this.props;
    const { copyright, userLinks } = config;
    return (
        <div/>
    );
  }
}
